import axios from 'axios'
import { ElMessage } from 'element-plus'

axios.interceptors.request.use(config => {
  return config
})

export function get (url, params, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.get(url, { params })
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

export function getWithCancel (_this, url, params, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.get(url, {
      params,
      cancelToken: axios.CancelToken(c => {
        _this.cancelRequest = c
      })
    })
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

export function post (url, data, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.post(url, data)
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

export function postWithCancel (_this, url, data, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.post(url, data, {
      cancelToken: axios.CancelToken(c => {
        _this.cancelRequest = c
      })
    })
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

export function deletee (url, params, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.delete(url, { params })
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

export function put (url, data, successMsg = '', failMsg = '') {
  return new Promise((resolve, reject) => {
    const request = axios.put(url, data)
    handleRequest(request, resolve, reject, successMsg, failMsg)
  })
}

function handleRequest (promise, resolve, reject, successMsg, failMsg) {
  promise
    .then(res => {
      handleResponse(res, resolve, reject, successMsg, failMsg)
    })
    .catch(err => {
      handleFail(failMsg)
      reject(err)
    })
}

/**
 * 处理响应
 * @param {*} response
 * @param {*} resolve
 * @param {*} reject
 * @param {*} successMsg
 * @param {*} fialMsg
 * @returns
 */
function handleResponse (response, resolve, reject, successMsg, failMsg) {
  if (!response.data) {
    reject(new Error(response.status))
    return
  }

  if (response.data.errorno === 0) {
    handleSuccess(successMsg)
    resolve(response.data.data)
    return
  }

  handleFail(failMsg)
  reject(new Error(response.data.message))
}

function handleSuccess (successMsg) {
  if (successMsg) {
    ElMessage.success(successMsg)
  }
}

function handleFail (fialMsg) {
  if (fialMsg) {
    ElMessage.error(fialMsg)
  }
}
